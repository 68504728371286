import BaseConverter from "@jordibosch20/software_tools_package/dist/pages/BaseConverter.component.js"
import styles from "./App.module.css";
import { convertToBase } from "@jordibosch20/software_tools_package/dist/utils/convertToBase";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <BaseConverter title={"Base Converter"} f={convertToBase}></BaseConverter>
      </div>
      <div className={styles.text}>
        <p><strong>The World of base transformations</strong></p>
        <p>Data and numbers form the backbone of everything from software development to academic research, thus, understanding how to work with different numerical bases is an invaluable skill. For that purpouse we have created this number base converter, a tool designed to simplify the complexities of numerical base conversion</p>
        <p><strong>Why Base Conversion Matters</strong></p>
        <p>Before we delve deeper, let's touch on the importance of base conversion. At its core, base conversion is the process of changing numbers from one base to another. Common bases include binary (base 2), used in computing and fundamental piece of how a computer works; decimal (base 10), used in everyday counting; and hexadecimal (base 16) and octal (base 8), also widely used also in programming and digital systems. Each base plays a critical role in various fields, making the ability to convert between them a necessary skill for many professionals and students.</p>
        <p><strong>Introducing our Number Base Converter</strong></p>
        <p>Designed to make your conversion process as smooth as possible. Whether you're converting complex hexadecimal codes into readable decimal numbers or translating binary data into a format understandable for your next project, our tool has got you covered.</p>
        <p><strong>Key Features:</strong></p>
        <ul>
        <li><strong>Wide Range of Bases:</strong> From binary to hexadecimal and beyond, convert numbers between any bases with ease. You can even chose the custom base you would like to convert to (up to base36)</li>
        <li><strong>Instant Results:</strong> All conversions are done momentaneusly in your mobile/computer.</li>
        <li><strong>User-Friendly Design:</strong> Our tool is built for everyone, regardless of expertise level.</li>
        <li><strong>Free to Use:</strong> Access our base conversion tool anytime, anywhere, for free.</li>
        </ul>
        <p><strong>How to Use the Converter</strong></p>
        <p>Using our number base converter is as easy as 1, 2, 3. Here's a quick guide to get you started:</p>
        <ol>
        <li><strong>Select Your Bases:</strong> Choose the base you're converting from and the base you want to convert to.</li>
        <li><strong>Enter Your Number:</strong> Type in the number you need to convert. You can type your input in any of the selectors</li>
        <li><strong>Do not even hit Convert:</strong> Once the number is written in the input, automatically all conversions will be done.</li>
        </ol>
        <p></p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>

  );
}

export default App;